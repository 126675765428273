import React, { Fragment, useEffect, useState } from "react";
import { getAuth, sendEmailVerification, signOut } from "firebase/auth";
import { useHistory } from "react-router-dom";
import app from "../utils/firebaseApp";

import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Transition } from '@headlessui/react'

const EmailVerificationNotice = () => {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [showSuccessCustom, setShowSuccessCustom] = useState(false);
    const [successCustomTitle, setSuccessCustomTitle] = useState("");
    const [successCustomSubtitle, setSuccessCustomSubtitle] = useState("");

    const [showErrorCustom, setShowErrorCustom] = useState(false);
    const [errorCustomTitle, setErrorCustomTitle] = useState("");
    const [errorCustomSubtitle, setErrorCustomSubtitle] = useState("");

    const auth = getAuth(app);
    const user = auth.currentUser;
    const history = useHistory();

    useEffect(() => {
        const interval = setInterval(async () => {
            try {
                if (user) {
                    await user.reload();
                    if (user.emailVerified) {
                        console.log("Email verified");
                        clearInterval(interval);
                        window.location.href = "/login";
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }, 2000); // Check every 2 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [user]);

    const showSuccessFunction = () => {
        setShowSuccessCustom(true);
        setTimeout(() => {
            setShowSuccessCustom(false);
            setSuccessCustomTitle("");
            setSuccessCustomSubtitle("");
        }, 2000);
    }

    const showErrorFunction = () => {
        setShowErrorCustom(true);
        setTimeout(() => {
            setShowErrorCustom(false);
            setErrorCustomTitle("");
            setErrorCustomSubtitle("");
        }, 2000);
    }

    const handleResendVerificationEmail = async () => {
        if (user) {
            setLoading(true);
            try {
                await sendEmailVerification(user);
                setSuccessCustomTitle("Success");
                setSuccessCustomSubtitle("Verification email sent! Please check your inbox.");
                showSuccessFunction();
            } catch (error) {
                setErrorCustomTitle("Error");

                if (error.code === "auth/too-many-requests") {
                    setErrorCustomSubtitle("Too many requests. Please try again later.");
                } else {
                    setErrorCustomSubtitle("An error occurred. Please try again later.");
                }

                showErrorFunction();
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDisconnect = async () => {
        try {
            await signOut(auth);
            history.push("/login");
        } catch (error) {
            setErrorCustomTitle("Error");
            setErrorCustomSubtitle(`Error: ${error.message}`);
            showErrorFunction();
        }
    };

    return (
        <div className="h-full bg-gray-50">
            <GoodNotificationCustom showError={showSuccessCustom} setShowError={setShowSuccessCustom} successfulTitle={successCustomTitle} successfulSubtitle={successCustomSubtitle} />
            <ErrorNotificationCustom showError={showErrorCustom} setShowError={setShowErrorCustom} errorTitle={errorCustomTitle} errorSubtitle={errorCustomSubtitle} />

            <div className="flex h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-10 w-auto"
                        src={process.env.PUBLIC_URL + '/logo.png'}
                        alt="Extracta.ai"
                    />
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <h2 className="text-left text-2xl font-bold leading-9 tracking-tight text-gray-900">
                            Please verify your email
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            We have sent a verification email to your email address. Please check your inbox and follow the instructions to verify your email.
                        </p>
                        <div className="mt-6">
                            <button
                                onClick={handleResendVerificationEmail}
                                disabled={loading}
                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {loading ? (
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.344 6.315 3.514 8.485l2.486-2.194z"></path>
                                    </svg>
                                ) : (
                                    "Resend Verification Email"
                                )}
                            </button>
                        </div>
                        {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}
                    </div>
                </div>
                <p className="mt-10 text-center text-sm text-gray-500">
                    Want to sign in with a different account?{' '}
                    <button onClick={handleDisconnect} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                        Disconnect
                    </button>
                </p>
            </div>
        </div>
    );
};

function GoodNotificationCustom({ showError, setShowError, successfulTitle, successfulSubtitle }) {
    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={showError}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{successfulTitle}</p>
                                        <p className="mt-1 text-sm text-gray-500">{successfulSubtitle}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setShowError(false)
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

function ErrorNotificationCustom({ showError, setShowError, errorTitle, errorSubtitle }) {
    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={showError}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{errorTitle}</p>
                                        <p className="mt-1 text-sm text-gray-500">{errorSubtitle}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={() => {
                                                setShowError(false)
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

export default EmailVerificationNotice;
