import { useState, useEffect, useContext } from 'react'


import app from "../utils/firebaseApp.js";
import { db } from '../utils/firebaseApp.js';
import { getAuth } from "firebase/auth";
import { AuthContext } from "../utils/auth.js";
import { get, ref, onValue, startAt, orderByKey, limitToFirst, query, set } from "firebase/database";

import axios from "axios";

import MenuBar from '../components/MenuBar.js';

import { AreaChart } from "@tremor/react";

import { DateRangePicker, DateRangePickerItem } from "@tremor/react";

import TopBar from "../components/TopBar.js";

import { ChevronRightIcon } from '@heroicons/react/20/solid'

import ModalCustom from '../components/utils/ModalCustom.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import ReactJson from 'react18-json-view';

import PaginationAdmin from './DataExtraction/ExtractionFolder/PaginationAdmin.js';

import {
  DocumentTextIcon,
  GlobeAltIcon, // For extractionLanguage
  CalendarIcon, // For extractionCreatedAt
  CubeIcon, // For extractionBatchCount (alternative for CollectionIcon)
  AdjustmentsHorizontalIcon,
  CodeBracketIcon,
} from '@heroicons/react/24/outline'; // Import outline icons from Heroicons v2

import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';

import ModalForViewFile from '../components/utils/ModalForViewFile.js';
import ViewFileAdmin from '../components/ViewFileAdmin.js';

import toast, { Toaster } from 'react-hot-toast';

function formatDate(dateString) {
  const options = { month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Admin() {
  const { currentUser } = useContext(AuthContext);
  let userId = currentUser.uid;

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const environment = process.env.REACT_APP_ENV;

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const [billing, setBilling] = useState([]);
  const [billingChart, setBillingChart] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [users, setUsers] = useState({});
  const [usersInfo, setUsersInfo] = useState({});

  const [authChart, setAuthChart] = useState([]);
  const [totalAuthRequests, setTotalAuthRequests] = useState(0);

  const today = new Date();

  const [startTime, setStartTime] = useState(new Date(today.getFullYear(), today.getMonth(), 1, 0, 0).getTime());
  const [endTime, setEndTime] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59).getTime());

  const [tempStartTime, setTempStartTime] = useState(new Date(today.getFullYear(), today.getMonth(), 1, 0, 0).getTime());
  const [tempEndTime, setTempEndTime] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59).getTime());

  const handleSignout = () => {
    getAuth(app).signOut(app).then(() => {
      // console.log("Signout")
    }).catch(() => {
      // console.log(error)
    });
  }

  const getUsers = (callback) => {
    currentUser.getIdToken().then((idToken) => {
      const url = serverUrl + "/admin/getUsers";

      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        if (!response.data) {
          callback([]);
          return;
        }
        console.log(response.data);
        callback(response.data);
      }).catch((error) => {
        console.log(error);
        callback([]);
      });
    });
  }

  const [selectedUser, setSelectedUser] = useState('');

  const fetchBilling = async () => {
    // Fetch all users
    getUsers((users) => {
      const userIds = {};
      const usersInfo = {};

      users.forEach((user) => {
        userIds[user.userId] = user.email;
        usersInfo[user.userId] = { ...user };
      });

      setUsers(userIds);
      setUsersInfo(usersInfo);

      const totalAuthData = [];

      // transform each user's data into an array element
      Object.keys(usersInfo).forEach((userId) => {
        const userAuth = usersInfo[userId];

        const timestamp = userAuth.creationTime;

        const month = formatDate(new Date(timestamp));
        // const month = new Date(timestamp).toLocaleString('default', { month: 'short' }) + ' ' + new Date(timestamp).getFullYear();

        const newTimestamp = new Date(timestamp);

        // Check if an auth entry for this timestamp already exists
        const existingEntry = totalAuthData.find((entry) => entry.day === month);

        if (existingEntry) {
          existingEntry.Users += 1;
        } else {
          // Create a new entry for this timestamp
          const newEntry = {
            date: newTimestamp,
            day: month,
            Users: 1,
          };

          totalAuthData.push(newEntry);
        }
      });

      // sort the data by timestamp in ascending order
      totalAuthData.sort((a, b) => a.date - b.date);

      // calculate total Requests
      let totalRequests = 0;
      totalAuthData.forEach((entry) => {
        totalRequests += entry.Users;
      });
      setTotalAuthRequests(totalRequests);

      setAuthChart(totalAuthData);
    });

    // Fetch all user billing data within the time range
    const billingRef = ref(db, 'billing');
    get(billingRef).then((snapshot) => {
      if (snapshot.exists()) {
        let allUsersData = snapshot.val();

        let testUserIds = [
          'WgVLxXREk7eW2aQeFOSfRbuxJbm1', // barzoiu
          '3fEgxedJEsfxRT1V4x4NLxgppbE2', // extracta
          'AYPeMWkuo3e1cE8WFZHG8Nr13IP2', // cristi
          'HhEhbxIKIZV9S9pXnM7hWeCICQl1', // bardahan
          'jDJ4hJMOt0fuCFZzOmj4tOTOL613', // cont test
          'SemdChEmcgNSmCxfbiXQCEk3ndE3', // test extracta
          'UU2xotn4DzN0ArEo0eOJk1ZkMms2', // rapidapi.testing@extracta.ai	
          'yIfPRqjK8VZq351Q4WvauTamYNA3', // extracta test 2
        ];

        // filter all users data. i dont want the users that are in the testUserIds
        allUsersData = Object.keys(allUsersData).reduce((acc, userId) => {
          if (!testUserIds.includes(userId)) {
            acc[userId] = allUsersData[userId];
          }
          return acc;
        }, {});

        // if selectedUser is not empty, then filter the data to keep only that id
        if (selectedUser !== '') {
          allUsersData = {
            [selectedUser]: allUsersData[selectedUser]
          };
        }

        const filteredData = [];
        const totalBillingData = [];

        // Transform each user's data into an array element
        Object.keys(allUsersData).forEach((userId) => {
          const userBilling = allUsersData[userId];
          let userBillingData = { userId, billingItems: [] };

          Object.keys(userBilling).forEach((billingId) => {
            // Check if billingId is 'success' or 'error'
            if (billingId === 'success' || billingId === 'error') {
              // billingItem contains multiple billings
              // Loop through each billing in the billingItem
              Object.keys(userBilling[billingId]).forEach((billingItemId) => {
                const billingItem = userBilling[billingId][billingItemId];

                if (billingItem.timestamp >= startTime && billingItem.timestamp <= endTime) {
                  userBillingData.billingItems.push(billingItem);

                  const timestamp = billingItem.timestamp;
                  const day = formatDate(new Date(timestamp));

                  // Check if a billing entry for this timestamp already exists
                  const existingEntry = totalBillingData.find((entry) => entry.day === day);

                  if (existingEntry) {
                    existingEntry.Requests += 1;
                  } else {
                    // Create a new entry for this timestamp
                    const newEntry = {
                      date: timestamp,
                      day: day,
                      Requests: 1,
                    };

                    totalBillingData.push(newEntry);
                  }
                }
              });

              return; // Stop the function
            }

            const billingItem = userBilling[billingId];
            if (billingItem.timestamp >= startTime && billingItem.timestamp <= endTime) {
              userBillingData.billingItems.push(billingItem);

              const timestamp = billingItem.timestamp;
              const day = formatDate(new Date(timestamp));

              // Check if a billing entry for this timestamp already exists
              const existingEntry = totalBillingData.find((entry) => entry.day === day);

              if (existingEntry) {
                existingEntry.Requests += 1;
              } else {
                // Create a new entry for this timestamp
                const newEntry = {
                  date: timestamp,
                  day: day,
                  Requests: 1,
                };

                totalBillingData.push(newEntry);
              }
            }
          });

          if (userBillingData.billingItems.length > 0) {
            filteredData.push(userBillingData);
          }
        });

        // sort the data by timestamp in ascending order
        totalBillingData.sort((a, b) => a.date - b.date);

        // sort the data by items for filteredData
        filteredData.sort((a, b) => b.billingItems.length - a.billingItems.length);

        // calculate total Requests
        let totalRequests = 0;
        totalBillingData.forEach((entry) => {
          totalRequests += entry.Requests;
        });

        setTotalRequests(totalRequests);
        setBillingChart(totalBillingData);
        setBilling(filteredData);
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  }

  const updateDates = (dates) => {
    // Check if 'from' date is valid
    if (!dates.from || isNaN(new Date(dates.from).getTime())) {
      return; // Stop the function if 'from' date is not valid
    }

    // Update startTime to 'from' date at 00:00
    const fromStartOfDay = new Date(dates.from);
    fromStartOfDay.setHours(0, 0, 0, 0);
    setTempStartTime(fromStartOfDay.getTime());

    // Update endTime based on the presence of 'to' date
    let toEndOfDay;
    if (dates.to && !isNaN(new Date(dates.to).getTime())) {
      // 'to' is valid, use 'to' date at 23:59
      toEndOfDay = new Date(dates.to);
    } else {
      // 'to' is not valid, use 'from' date at 23:59
      toEndOfDay = new Date(dates.from);
    }
    toEndOfDay.setHours(23, 59, 59, 999);
    setTempEndTime(toEndOfDay.getTime());
  }

  const updateDatesAndFetch = () => {
    // Check if both tempStartTime and tempEndTime are present and valid
    if (tempStartTime && tempEndTime && !isNaN(new Date(tempStartTime).getTime()) && !isNaN(new Date(tempEndTime).getTime())) {
      // Update startTime and endTime with tempStartTime and tempEndTime
      setStartTime(tempStartTime);
      setEndTime(tempEndTime);
    }
  };

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    document.title = "Admin - " + appName;

    const fetchData = async () => {
      await fetchBilling();
    }
    fetchData();
  }, [userId, startTime, endTime, selectedUser]);

  const [viewNumber, setViewNumber] = useState(0);

  const changeSelectedUser = (userId) => {
    setSelectedUser(userId);
  }

  return (
    <>
      <div>
        <MenuBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} currentHref="admin" />

        <main className="lg:pl-72">
          <TopBar setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} />

          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className='px-3 hidden md:block mb-8'>
              <a href='/admin' className="text-4xl font-bold text-gray-900">Admin</a>
              <p className="text-m text-gray-500"></p>
            </div>

            <div className='w-full px-3'>
              <TabsDashboard setViewNumber={setViewNumber} viewNumber={viewNumber} />
              <div className='mb-2'></div>

              {viewNumber === 0 &&
                <ExtractionsDashboard
                  billing={billing}
                  updateDates={updateDates}
                  startTime={startTime}
                  endTime={endTime}
                  updateDatesAndFetch={updateDatesAndFetch}
                  billingChart={billingChart}
                  totalRequests={totalRequests}
                  users={users}
                  changeSelectedUser={changeSelectedUser}
                  selectedUser={selectedUser}
                />
              }

              {viewNumber === 1 &&
                <AuthDashboard
                  usersInfo={usersInfo}
                  authChart={authChart}
                  totalAuthRequests={totalAuthRequests}
                />
              }

              {viewNumber === 2 &&
                <CreditsDashboard
                  usersInfo={usersInfo}
                />
              }

              {viewNumber === 3 &&
                <SubscriptionsDashboard
                  usersInfo={usersInfo}
                />
              }

              {viewNumber === 4 &&
                <CreditsDashboardHistory
                  usersInfo={usersInfo}
                />
              }
            </div>
          </div >
        </main >
      </div >
    </>
  )
}

function TabsDashboard({ setViewNumber, viewNumber }) {
  const tabs = [
    { name: 'Extractions', href: '#', current: viewNumber === 0, index: 0 },
    { name: 'Authentication', href: '#', current: viewNumber === 1, index: 1 },
    { name: 'Credits', href: '#', current: viewNumber === 2, index: 2 },
    { name: 'Subscriptions', href: '#', current: viewNumber === 3, index: 3 },
    { name: 'Buy button history', href: '#', current: viewNumber === 4, index: 4 },
  ];

  const handleChange = (event) => {
    const selectedTabName = event.target.value;
    const selectedTabIndex = tabs.find((tab) => tab.name === selectedTabName).index;
    setViewNumber(selectedTabIndex);
  };

  return (
    <div>
      <div className="sm:hidden -mx-4 -my-2">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={handleChange}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setViewNumber(tab.index)}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}


function DateRangePickerWidget({ updateDates }) {
  const today = new Date();

  // get current month
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  // get current week
  const firstDayOfCurrentWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
  const lastDayOfCurrentWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 6);

  // get last month
  const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  // get last week
  const firstDayOfLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7);
  const lastDayOfLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1);

  // get all time 
  const allTime = new Date(2023, 9, 1);

  // get last 30 days
  const last30Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);

  // get yesterday
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <DateRangePicker
      className="max-w-sm"
      weekStartsOn={1}
      selectPlaceholder="Custom"
      placeholder="Select date"
      onValueChange={(values) => {
        updateDates(values);
      }}
    >
      <DateRangePickerItem
        key="today"
        value="today"
        from={today}
        to={today}
      >
        Today
      </DateRangePickerItem>
      <DateRangePickerItem
        key="yesterday"
        value="yesterday"
        from={yesterday}
        to={yesterday}
      >
        Yesterday
      </DateRangePickerItem>
      <DateRangePickerItem
        key="last30Days"
        value="last30Days"
        from={last30Days}
        to={today}
      >
        Last 30 Days
      </DateRangePickerItem>
      <DateRangePickerItem
        key="ytd"
        value="ytd"
        from={firstDayOfCurrentMonth}
        to={lastDayOfCurrentMonth}
      >
        Current Month
      </DateRangePickerItem>
      <DateRangePickerItem
        key="half"
        value="half"
        from={firstDayOfCurrentWeek}
        to={lastDayOfCurrentWeek}
      >
        Current Week
      </DateRangePickerItem>
      <DateRangePickerItem
        key="lastMonth"
        value="lastMonth"
        from={firstDayOfLastMonth}
        to={lastDayOfLastMonth}
      >
        Last Month
      </DateRangePickerItem>
      <DateRangePickerItem
        key="lastWeek"
        value="lastWeek"
        from={firstDayOfLastWeek}
        to={lastDayOfLastWeek}
      >
        Last Week
      </DateRangePickerItem>
      <DateRangePickerItem
        key="allTime"
        value="allTime"
        from={allTime}
        to={today}
      >
        All Time
      </DateRangePickerItem>
    </DateRangePicker>
  );
}

function AuthDashboard({ usersInfo, authChart, totalAuthRequests }) {
  // calculate number of users
  const totalUsers = Object.keys(usersInfo).length;

  // convert usersInfo object to array and sort by creationTime
  const sortedUsers = Object.values(usersInfo).sort((a, b) => {
    const dateA = new Date(a.creationTime);
    const dateB = new Date(b.creationTime);
    return dateB - dateA; // sort in descending order
  });

  // i need to know how many new users today
  const today = new Date();
  const todayUsers = sortedUsers.filter((user) => {
    const userCreationDate = new Date(user.creationTime);
    return userCreationDate.getDate() === today.getDate() &&
      userCreationDate.getMonth() === today.getMonth() &&
      userCreationDate.getFullYear() === today.getFullYear();
  });
  const newUsersToday = todayUsers.length;

  // const formatDate as dd MMM yyyy, hh:mm
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }


  return (
    <div className="mt-4 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <DataDisplayAuth newUsers={newUsersToday} totalUsers={totalUsers} />

          <div className="mt-5 overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border">
            <dt className="truncate text-sm font-medium text-gray-500">Authentication</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              {totalAuthRequests} users
              <UsersChart chartData={authChart} />
            </dd>
          </div>

          <div className='mb-5'></div>

          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    User Id
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Provider
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Email verified?
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created At
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Last Login
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Object.values(sortedUsers).map((userBilling, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {userBilling.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {userBilling.userId}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {userBilling.provider}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {userBilling.confirmed ? 'Yes' : (
                        <span className='text-red-500'>No</span>
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {formatDate(userBilling.creationTime)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {formatDate(userBilling.lastSignInTime)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const UsersChart = ({ chartData }) => {
  return (
    <AreaChart
      className="h-60 mt-4"
      data={chartData}
      index="day"
      categories={["Users"]}
      colors={["indigo", "cyan", "red"]}
      showAnimation={true}
      showLegend={true}
      allowDecimals={false}
      valueFormatter={valueFormatter}
      customTooltip={customTooltipUsers}
      yAxisWidth={68}
    />
  );
}

function DataDisplayAuth({ newUsers, totalUsers }) {
  const stats = [
    { name: 'New Users Today', stat: newUsers },
    { name: 'Total Users', stat: totalUsers },
  ]
  return (
    <div>
      <dl className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

function DataDisplayExtractions({ newUsers, totalUsers }) {
  const stats = [
    { name: 'New Users Today', stat: newUsers },
    { name: 'Total Users', stat: totalUsers },
  ]
  return (
    <div>
      <dl className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  complete: 'text-green-700 bg-green-50 ring-green-600/20',
  finished: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  'in progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  processing: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}

function ShowUserDetails({ userId, closeModal }) {
  // i need to call when entering this function to get the user details
  const [userDetails, setUserDetails] = useState({});
  const [extractionsList, setExtractionsList] = useState([]);

  const [selectedExtraction, setSelectedExtraction] = useState({});
  const [batchesList, setBatchesList] = useState([]);

  const [selectedBatch, setSelectedBatch] = useState({});
  const [files, setFiles] = useState([]);
  const [fields, setFields] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(10);
  const itemsPerPage = 10;


  const [currentBatchPage, setCurrentBatchPage] = useState(1);
  const itemsPerBatchPage = 10; // Number of batches per page
  const [noOfPagesBatch, setNoOfPagesBatch] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  const maxCharactersForField = 75;
  const maxCharactersForFieldObject = 50;

  const [viewType, setViewType] = useState('extractions');

  const fetchUserDetails = async () => {
    const userDetailsRef = ref(db, 'userDetails/' + userId);
    get(userDetailsRef).then((snapshot) => {
      if (snapshot.exists()) {
        setUserDetails(snapshot.val());
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  }

  const fetchExtractionsListOld = async () => {
    const extractionsListRef = ref(db, 'extractions/' + userId);
    get(extractionsListRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data != null) {
          let tempExtractions = [];
          Object.keys(data).forEach(function (key) {
            let extraction = data[key];
            extraction.id = key;

            let batches = extraction.batches ?? [];

            // Update total pages for batches list
            const noOfBatches = extraction.batches ? Object.keys(extraction.batches).length : 0;
            setNoOfPagesBatch(Math.ceil(noOfBatches / itemsPerBatchPage));

            let extractionStatus = 'complete';
            Object.keys(batches).forEach(function (key) {
              let batch = batches[key];
              if (batch.status === 'processing') {
                extractionStatus = 'processing';
              }
            });

            let newExtraction = {
              name: extraction.name ?? 'undefined',
              description: extraction.description ?? '',
              status: extractionStatus,
              language: extraction.language,
              noOfBatches: 0,
              noOfFields: 0,
              batches: extraction.batches,
              fields: extraction.fields,
              id: extraction.id,
              createdAt: extraction.createdAt ?? Date.now(),
            }

            if (extraction.batches && typeof extraction.batches === 'object') {
              newExtraction.noOfBatches = Object.keys(extraction.batches).length;
            } else {
              newExtraction.noOfBatches = 0;
            }

            if (extraction.fields && typeof extraction.fields === 'object') {
              newExtraction.noOfFields = Object.keys(extraction.fields).length;
            } else {
              newExtraction.noOfFields = 0;
            }

            tempExtractions.push(newExtraction);
          });

          const sortedTempExtractions = tempExtractions.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));
          setExtractionsList(sortedTempExtractions);
        }
      } else {
        console.log('No data available');
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
    });
  }

  const fetchExtractionsList = async () => {
    try {
      // Fetch all extractions
      const extractionsListRef = ref(db, 'extractions/' + userId);
      const extractionsSnapshot = await get(extractionsListRef);

      // Fetch all batches at once
      const batchesListRef = ref(db, 'batches/' + userId);
      const batchesSnapshot = await get(batchesListRef);

      if (!extractionsSnapshot.exists()) {
        console.log('No extractions available');
        setExtractionsList([]);
        return;
      }

      const extractionsData = extractionsSnapshot.val() || {};
      const batchesData = batchesSnapshot.exists() ? batchesSnapshot.val() : {};

      let tempExtractions = [];

      Object.keys(extractionsData).forEach((extractionId) => {
        let extraction = extractionsData[extractionId] || {};
        extraction.id = extractionId;

        // Attach batches from the fetched data
        const extractionBatches = batchesData[extractionId] || {};

        // Determine extraction status based on batches
        let extractionStatus = 'complete';
        Object.values(extractionBatches).forEach((batch) => {
          if (batch.status === 'processing') {
            extractionStatus = 'processing';
          }
        });

        let newExtraction = {
          name: extraction.name ?? 'undefined',
          description: extraction.description ?? '',
          status: extractionStatus,
          language: extraction.language,
          noOfBatches: Object.keys(extractionBatches).length,
          noOfFields: extraction.fields ? Object.keys(extraction.fields).length : 0,
          batches: extractionBatches,
          fields: extraction.fields,
          id: extraction.id,
          createdAt: extraction.createdAt ?? Date.now(),
        };

        tempExtractions.push(newExtraction);
      });

      // Sort the extractions by creation date
      const sortedTempExtractions = tempExtractions.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));
      setExtractionsList(sortedTempExtractions);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchBatchesListOld = async (extractionId) => {
    setBatchesList([]); // Clear old data
    setIsLoading(true); // Optional loading state
    const batchesListRef = ref(db, 'extractions/' + userId + '/' + extractionId + '/batches');

    get(batchesListRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data != null) {
          let tempBatches = [];

          Object.keys(data).forEach(function (key) {
            let batch = data[key];
            batch.id = key;

            let numberOfDocs = 0;
            try {
              numberOfDocs = batch.files ? batch.files.length : 0;
            } catch (error) {
              numberOfDocs = 0;
            }

            let newBatch = {
              id: batch.id,
              name: 'Batch ' + (batch.id),
              numberOfDocuments: numberOfDocs,
              startDateTime: batch.startTime,
              status: batch.status,
            }

            tempBatches.push(newBatch);
          });

          // Sort the batches
          const sortedTempBatches = tempBatches.sort((a, b) => Number(b.startDateTime) - Number(a.startDateTime));

          // Calculate pagination details
          let totalItems = sortedTempBatches.length;
          let tempNoOfPages = Math.ceil(totalItems / itemsPerBatchPage);
          setNoOfPagesBatch(tempNoOfPages);

          // Ensure current page is valid
          if (currentBatchPage > tempNoOfPages) {
            setCurrentBatchPage(tempNoOfPages > 0 ? tempNoOfPages : 1);
          }

          // Paginate the batches for the current page
          let start = (currentBatchPage - 1) * itemsPerBatchPage;
          let end = start + itemsPerBatchPage;
          let paginatedBatches = sortedTempBatches.slice(start, end);

          setBatchesList(paginatedBatches);
          setIsLoading(false); // Optional to hide loading state
        }
      } else {
        console.log('No data available');
        setBatchesList([]);
        setIsLoading(false);
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    });
  }

  const fetchBatchesList = async (extractionId) => {
    setBatchesList([]); // Clear old data
    setIsLoading(true); // Optional loading state

    // Updated path: from 'extractions/' + userId + '/' + extractionId + '/batches'
    // to 'batches/' + userId + '/' + extractionId
    const batchesListRef = ref(db, 'batches/' + userId + '/' + extractionId);

    get(batchesListRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        if (data != null) {
          let tempBatches = [];

          Object.keys(data).forEach(function (key) {
            let batch = data[key];
            batch.id = key;

            let numberOfDocs = 0;
            try {
              numberOfDocs = batch.files ? batch.files.length : 0;
            } catch (error) {
              numberOfDocs = 0;
            }

            let newBatch = {
              id: batch.id,
              name: 'Batch ' + batch.id,
              numberOfDocuments: numberOfDocs,
              startDateTime: batch.startTime,
              status: batch.status,
            }

            tempBatches.push(newBatch);
          });

          // Sort the batches
          const sortedTempBatches = tempBatches.sort((a, b) => Number(b.startDateTime) - Number(a.startDateTime));

          // Calculate pagination details
          let totalItems = sortedTempBatches.length;
          let tempNoOfPages = Math.ceil(totalItems / itemsPerBatchPage);
          setNoOfPagesBatch(tempNoOfPages);

          // Ensure current page is valid
          if (currentBatchPage > tempNoOfPages) {
            setCurrentBatchPage(tempNoOfPages > 0 ? tempNoOfPages : 1);
          }

          // Paginate the batches for the current page
          let start = (currentBatchPage - 1) * itemsPerBatchPage;
          let end = start + itemsPerBatchPage;
          let paginatedBatches = sortedTempBatches.slice(start, end);

          setBatchesList(paginatedBatches);
          setIsLoading(false); // Optional to hide loading state
        }
      } else {
        console.log('No data available');
        setBatchesList([]);
        setIsLoading(false);
      }
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    });
  }

  const [unsubscribeFilesListener, setUnsubscribeFilesListener] = useState(null);

  const loadFiles = (selBatch) => {
    setFiles([]);

    let queryPath = 'files/' + userId + '/' + selectedExtraction.id + '/' + selBatch.id;
    let itemsRef = ref(db, queryPath)

    let tempKey;
    let itemsQuery;

    console.log(selectedExtraction);
    console.log(selBatch);

    tempKey = selectedExtraction.batches[selBatch.id].files[(currentPage - 1) * itemsPerPage];
    itemsQuery = query(itemsRef, orderByKey());

    const unsubscribe = onValue(itemsQuery, (snapshot) => {
      const data = snapshot.val();
      let tempFiles = [];

      if (data) {
        console.log('data', data);

        Object.keys(data).forEach((key) => {
          let file = data[key];
          file.id = key;

          let newFile = {
            id: file.id,
            name: file.name ?? 'undefined',
            url: file.url,
            status: file.status,
            errorMessage: file.errorMessage,
            result: file.result ?? {},
          }

          tempFiles.push(newFile);
        });
        setFiles(tempFiles);
      } else {
        setFiles([]);
        console.log('No data available');
      }
    });

    setUnsubscribeFilesListener(() => unsubscribe);
  }

  const nextBatchPage = () => {
    if (currentBatchPage < noOfPagesBatch) {
      setCurrentBatchPage(currentBatchPage + 1);
    }
  };

  const previousBatchPage = () => {
    if (currentBatchPage > 1) {
      setCurrentBatchPage(currentBatchPage - 1);
    }
  };

  const selectBatchPage = (pageNumber) => {
    if (currentBatchPage !== pageNumber) {
      setCurrentBatchPage(pageNumber);
    }
  };

  const changeView = (type) => {
    if (type === 'extractions') {
      setSelectedExtraction({});
      setBatchesList([]);
      setSelectedBatch({});
      setFields([]);
      setFiles([]);
    }

    if (type === 'batches') {
      setSelectedBatch({});
      setFields([]);
      setFiles([]);
    }

    if (type === 'batch') {
      // do nothing
    }

    setViewType(type);
  }

  const changeViewType = ({ type, extractionId, batchId }) => {
    console.log(type);
    console.log(extractionId);
    console.log(batchId);

    if (type === 'batches') {
      setSelectedExtraction(extractionsList.find((extraction) => extraction.id === extractionId));
      fetchBatchesList(extractionId);
    }

    if (type === 'batch') {
      setSelectedBatch(batchesList.find((batch) => batch.id === batchId));
      setFields(selectedExtraction.fields ?? []);
      setNoOfPages(Math.ceil(selectedExtraction.batches[batchId].files.length / itemsPerPage));

      const selBatchList = batchesList.find((batch) => batch.id === batchId);
      loadFiles(selBatchList);
    }

    setViewType(type);
  }

  useEffect(() => {
    if (viewType === 'batches' && selectedExtraction.id) {
      fetchBatchesList(selectedExtraction.id);
    }
  }, [currentBatchPage, selectedExtraction.id, viewType]);

  useEffect(() => {
    fetchUserDetails();
    fetchExtractionsList();
  }, [userId]);

  const pages = [
    { name: 'Batches', type: 'batches' },
    { name: 'Batch', type: 'batch' },
  ];

  function formatExtractionsDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(Number(dateString)).toLocaleDateString(undefined, options);
  }

  let numberOfCredits = userDetails.credits?.credits ?? 0;
  numberOfCredits = numberOfCredits / 10;
  numberOfCredits = numberOfCredits.toFixed(0);

  let emailAddress = userDetails.account?.emailAddress ?? '-';

  const [showFileBool, setShowFileBool] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    file_id: '',
    file_url: '',
    file_name: '',
    status: '',
    data: {},
  });

  const openFileResult = async (file) => {
    setSelectedFile({
      file_id: file.id,
      file_url: file.url,
      file_name: file.name,
      status: file.status,
      data: file.result,
    })

    setShowFileBool(true);
  }

  return (
    <div className='w-full h-full flex flex-col items-start p-14'>
      <p className='mb-3'>Details for user: {userId}</p>
      <p className='mb-3'>Email address: {emailAddress}</p>
      <p className='mb-3'>Pages: {numberOfCredits} pages</p>

      <ModalForViewFile open={showFileBool} setOpen={setShowFileBool} >
        <ViewFileAdmin
          closeModal={() => {
            setShowFileBool(false);
            setSelectedFile({
              file_id: '',
              file_url: '',
              file_name: '',
              status: '',
              data: {},
            });
          }}
          extractionId={selectedExtraction.id}
          batchId={selectedBatch.id}
          fileData={selectedFile}
          extraction={selectedExtraction}
          initialFileStatus={'All'}
          uid={userId}
        />
      </ModalForViewFile>

      <nav className="flex mb-5" aria-label="Breadcrumb">
        <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
          <li className="flex h-11">
            <div className="flex items-center">
              <button
                onClick={() => changeView('extractions')}
                className="text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Extractions
              </button>
            </div>
          </li>
          {(viewType === 'batches' || viewType === 'batch') &&
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <button
                  onClick={() => changeView('batches')}
                  className={classNames(viewType === 'batches' ? "text-indigo-500 hover:text-indigo-700" : "", "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700")}
                >
                  Batches
                </button>
              </div>
            </li>}
          {viewType === 'batch' &&
            <li className="flex">
              <div className="flex items-center">
                <svg
                  className="h-full w-6 flex-shrink-0 text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <button
                  onClick={() => changeView('batch')}
                  className={classNames(viewType === 'batch' ? "text-indigo-500 hover:text-indigo-700" : "", "ml-4 text-sm font-medium text-gray-500 hover:text-gray-700")}
                >
                  Batch
                </button>
              </div>
            </li>}
        </ol>
      </nav>

      {viewType === 'extractions' &&
        <div className='w-full'>
          <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
            {extractionsList.map((extraction) => (
              <li
                key={extraction.id}
                className="cursor-pointer gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
              >
                <button
                  onClick={() => changeViewType({
                    type: 'batches',
                    extractionId: extraction.id
                  })}
                  className='w-full relative flex justify-between'
                >
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900">{extraction.name}</p>
                        <p
                          className={classNames(
                            statuses[extraction.status],
                            'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                          )}
                        >
                          {extraction.status}
                        </p>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        {(extraction.description !== null && extraction.description.length > 0) &&
                          <>
                            <p className="whitespace-nowrap">
                              {extraction.description}
                            </p>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                              <circle cx={1} cy={1} r={1} />
                            </svg>
                          </>
                        }
                        <p className="truncate">{extraction.noOfBatches} Batches</p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                          <circle cx={1} cy={1} r={1} />
                        </svg>
                        <p className="truncate">{extraction.noOfFields} Fields</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex shrink-0 items-center gap-x-4">
                    <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </div>
                </button>
              </li>
            ))}
          </ul>

          {extractionsList.length === 0 &&
            <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-8">
              <div className="text-center">
                <h2 className="text-xl font-semibold text-gray-900">No extractions yet</h2>
                <p className="mt-1.5 text-sm text-gray-600">
                  Get started by creating a new extraction.
                </p>
              </div>
            </div>
          }
        </div>
      }

      {viewType === 'batches' &&
        <div className='w-full'>
          <div className="">
            <ExtractionHeading
              extractionName={selectedExtraction.name}
              extractionLanguage={selectedExtraction.language}
              extractionCreatedAt={formatDate(selectedExtraction.createdAt)}
              extractionBatchCount={selectedExtraction.length}
              extractionStatus={selectedExtraction.status}
              extractionFieldsCount={selectedExtraction.fields?.length || 0}
              extractionFields={selectedExtraction.fields}
              deleteExtraction={() => selectedExtraction()}
            />
          </div>

          <div>
            <PaginationAdmin
              numberOfPages={noOfPagesBatch}
              currentPageNumber={currentBatchPage}
              previousButton={previousBatchPage}
              nextButton={nextBatchPage}
              selectPage={() => { }}
              itemsPerPage={0}
              setItemsPerPage={() => { }}
            />
          </div>

          <div className="mt-5 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Id
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          No. of documents
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Created at
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {batchesList.map((batch) => (
                        <tr
                          key={batch.id}
                          className="hover:bg-gray-50 cursor-pointer"
                          onClick={() => changeViewType({
                            type: 'batch',
                            extractionId: selectedExtraction.id,
                            batchId: batch.id
                          })}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {batch.id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {batch.numberOfDocuments} {batch.numberOfDocuments === 1 ? 'document' : 'documents'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {formatExtractionsDate(batch.startDateTime)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <p
                              className={classNames(
                                statuses[batch.status],
                                'rounded-md inline-block mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                              )}
                            >
                              {batch.status}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {viewType === 'batch' &&
        <div className='w-full'>
          <div className="">
            <BatchHeading
              batchName={"Batch"}
              batchCreatedAt={formatExtractionsDate(selectedExtraction?.batches[selectedBatch.id].startTime)}
              batchUpdatedAt={formatExtractionsDate(selectedExtraction?.batches[selectedBatch.id].startTime)}
              batchFilesCount={selectedExtraction?.batches[selectedBatch.id].files.length}
              batchFieldsCount={selectedExtraction?.fields.length}
              batchOrigin={selectedExtraction?.batches[selectedBatch.id].origin}
            />
          </div>

          <div className="mt-5">
            <p>Batch id: {selectedBatch.id}</p>
          </div>

          <div className="mt-5 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto overflow-y-hidden sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="relative">
                  <table className="min-w-full table-fixed divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                          {"File name".toUpperCase()}
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {"Status".toUpperCase()}
                        </th>
                        {fields.map((field) => (
                          <th key={field.key} scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            {field.key.toUpperCase()}
                          </th>
                        ))}
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {files.map((file, index) => (
                        <tr key={file.id}>
                          <td
                            className={classNames(
                              'whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900',
                            )}
                          >
                            <button onClick={() => openFileResult(file)}>
                              <span className='group-hover:underline group-hover:text-indigo-600 mr-1.5 cursor-pointer'>
                                {file.name}
                              </span>
                            </button>
                          </td>
                          <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className='text-red-300'>
                              {file.status === 'error' ? 'error' : ''}
                              {file.status === 'error' ? (
                                file.errorMessage !== undefined ? (': ' + file.errorMessage) : ''
                              ) : ''}
                            </span>
                            <span className='text-orange-300'>
                              {file.status === 'waiting' ? 'processing' : ''}
                            </span>
                            <span className=''>
                              {file.status === 'processed' ? 'done' : ''}
                            </span>
                          </td>
                          {fields.map((field, index) => {
                            const value = file.result[field.key];
                            let displayValue;
                            let titleValue = ''; // Used for hover tooltip
                            let type = '';

                            if (typeof value === 'string') {
                              displayValue = value.length > maxCharactersForField ? value.slice(0, maxCharactersForField) + '...' : value;
                              titleValue = value.length > maxCharactersForField ? value : '';
                              type = 'string';
                            } else if (value) {
                              // Convert object or list to string
                              const stringValue = JSON.stringify(value);
                              displayValue = stringValue.length > maxCharactersForFieldObject ? stringValue.slice(0, maxCharactersForFieldObject) + '...' : stringValue;
                              titleValue = stringValue.length > maxCharactersForFieldObject ? stringValue : '';
                              type = 'object';
                            } else {
                              displayValue = '-';
                            }

                            if (type === 'object') {
                              return (
                                <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div className='flex flex-row'>
                                    <div className="truncate" title={titleValue}>
                                      {displayValue}
                                    </div>
                                    <JsonViewerWindow key={index} jsonData={value} />
                                  </div>
                                </td>
                              );
                            } else {
                              return (
                                <td key={index} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div className="truncate" title={titleValue}>
                                    {displayValue}
                                  </div>
                                </td>
                              );
                            }
                          })}
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3 relative">
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-20'></div>
        </div>
      }
    </div>
  )
}

function ExtractionHeading({
  extractionName,
  extractionLanguage,
  extractionCreatedAt,
  extractionBatchCount,
  extractionStatus,
  extractionFieldsCount,
  extractionFields,
  editExtraction,
  deleteExtraction,
}) {
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {extractionName}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <GlobeAltIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {extractionLanguage}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CubeIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {extractionBatchCount} Batche(s)
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <AdjustmentsHorizontalIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {extractionFieldsCount} Field(s)
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {extractionCreatedAt}
          </div>
        </div>
        <div>
          {extractionFields.map((field) => (
            <div key={field.key} className="mt-2 flex items-center text-sm text-gray-500">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <AdjustmentsHorizontalIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                {field.key}, '{field.type ?? '-no type-'}', '{field.description ?? '-no description-'}', '{field.example ?? '-no example-'}'
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function BatchHeading({
  batchName,
  batchCreatedAt,
  batchUpdatedAt,
  batchFilesCount,
  batchFieldsCount,
  batchOrigin,
  deleteBatch,
}) {
  const originIcon = (batchOrigin) => {
    switch (batchOrigin) {
      case 'web':
        return <GlobeAltIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />;
      case 'api':
        return <CodeBracketIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />;
      default:
        return null; // Return null or a default icon if needed
    }
  };

  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {batchName}
        </h2>
        <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <DocumentTextIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {batchFilesCount} File(s)
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <AdjustmentsHorizontalIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {batchFieldsCount} Field(s)
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            {originIcon(batchOrigin)} {/* Displaying the origin icon */}
            Origin: {batchOrigin}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Created: {batchCreatedAt}
          </div>
          {/* <div className="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Updated: {batchUpdatedAt}
          </div> */}
        </div>
      </div>
      {/* <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <span className="ml-3">
            <button
              onClick={() => deleteBatch()}
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <TrashIcon className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              Delete Batch
            </button>
          </span>
        </div> */}
    </div>
  );
}

function JsonViewerWindow({ jsonData }) {
  // State to control the visibility of the modal
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to handle outside click
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setModalOpen(false);
    }
  };

  const jsonViewerStyle = {
    maxHeight: '80vh', // Maximum height of the container
    overflowY: 'auto', // Enable vertical scrolling
    wordBreak: 'break-all', // Breaks long words to prevent overflow
  };

  return (
    <div className="">
      {/* Button with only icon, small, to trigger the modal */}
      <button
        onClick={() => setModalOpen(true)}
        className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-100 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <FontAwesomeIcon icon={faCode} />
      </button>

      {/* Modal content */}
      {isModalOpen && (
        <div
          onClick={handleOutsideClick}
          className="fixed inset-0 flex items-center justify-center z-50 shadow-xl"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="bg-white rounded-lg w-1/3 p-6 shadow-xl relative"
            style={jsonViewerStyle}
          >
            {/* Close icon */}
            <span
              onClick={() => setModalOpen(false)}
              className="absolute top-4 right-4 cursor-pointer text-xl font-bold hover:text-gray-600"
            >
              &times;
            </span>
            {/* <JsonView
              src={jsonData}
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
            /> */}
            <ReactJson
              src={jsonData}
              theme="vscode"
              enableClipboard={false}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function Pagination({ numberOfPages, currentPageNumber, previousButton, nextButton, selectPage }) {
  const renderPageNumbers = () => {
    const pageNumbers = [];

    // Display up to 3 page numbers on either side of current page, with "..." ellipses for gaps
    for (let i = 1; i <= numberOfPages; i++) {
      if (i === 1 || i === numberOfPages || (i >= currentPageNumber - 2 && i <= currentPageNumber + 2)) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => selectPage(i)}
            className={`inline-flex items-center border-t-2 ${i === currentPageNumber
              ? "border-indigo-500 text-indigo-600"
              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              } px-4 pt-4 text-sm font-medium`}
            aria-current={i === currentPageNumber ? "page" : undefined}
          >
            {i}
          </button>
        );
      } else if (i < currentPageNumber - 2) {
        if (pageNumbers[pageNumbers.length - 1] !== '...') {
          pageNumbers.push('...');
        }
      } else if (i > currentPageNumber + 2) {
        if (pageNumbers[pageNumbers.length - 1] !== '...') {
          pageNumbers.push('...');
        }
      }
    }

    // iterate over pageNumbers array and when the value is '...', replace it with a span containing an ellipsis
    for (let i = 0; i < pageNumbers.length; i++) {
      if (pageNumbers[i] === '...') {
        pageNumbers[i] = <span key={i + i} className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">...</span>
      }
    }

    return pageNumbers;
  };

  return (
    <nav className="mt-4 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={previousButton}
          disabled={currentPageNumber === 1}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${currentPageNumber === 1 ? "text-gray-300" : "text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
          aria-disabled={currentPageNumber === 1}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {renderPageNumbers()}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={nextButton}
          disabled={currentPageNumber === numberOfPages}
          className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium ${currentPageNumber === numberOfPages ? "text-gray-300" : "text-gray-500 hover:border-gray-300 hover:text-gray-700"}`}
          aria-disabled={currentPageNumber === numberOfPages}
        >
          Next
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
}

function ExtractionsDashboard({
  billing,
  updateDates,
  startTime,
  endTime,
  updateDatesAndFetch,
  billingChart,
  totalRequests,
  users,
  changeSelectedUser,
  selectedUser,
}) {
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(Number(dateString)).toLocaleDateString(undefined, options);
  }

  // admin emails that need to be included in extracta.ai
  const adminEmails = ['barzoiu.alexandru@gmail.com', 'office@extracta.ai'];

  // Helper function to check if the email belongs to extracta.ai
  const isExtractaUser = (userId) => {
    const email = users[userId];
    return email && email.endsWith('@extracta.ai') && !adminEmails.includes(email) && email !== 'pradeep@extracta.ai';
  };

  // Helper function to check if the email belongs to the team
  const isTeamUser = (userId) => {
    const email = users[userId];
    return email && adminEmails.includes(email);
  };

  // Filtering billing data into two arrays based on the domain
  let extractaAiBilling = billing.filter(userBilling => isExtractaUser(userBilling.userId));
  let extractaAiTotalRequests = extractaAiBilling.reduce((total, userBilling) => total + userBilling.billingItems.length, 0);

  let teamBilling = billing.filter(userBilling => isTeamUser(userBilling.userId));
  let teamTotalRequests = teamBilling.reduce((total, userBilling) => total + userBilling.billingItems.length, 0);

  let otherBilling = billing.filter(userBilling => !isExtractaUser(userBilling.userId) && !isTeamUser(userBilling.userId));
  let otherTotalRequests = otherBilling.reduce((total, userBilling) => total + userBilling.billingItems.length, 0);

  // filter all data based on the selected user
  // const selectedUserId = '2ZGGPgPlUWgAu5eaerXNmszIIKy2';
  // if (selectedUserId !== undefined) {
  //   extractaAiBilling = extractaAiBilling.filter(userBilling => userBilling.userId === selectedUserId);
  //   teamBilling = teamBilling.filter(userBilling => userBilling.userId === selectedUserId);
  //   otherBilling = otherBilling.filter(userBilling => userBilling.userId === selectedUserId);
  // }

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [open, setOpen] = useState(false);

  const handleButtonClick = (userId) => {
    console.log('Button clicked for user:', userId);
    setSelectedUserId(userId);
    setOpen(true);
  }

  return (
    <div className="">
      <ModalCustom open={open} setOpen={setOpen} >
        <ShowUserDetails userId={selectedUserId} closeModal={() => {
          setOpen(false);
          setSelectedUserId('');
        }} />
      </ModalCustom>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className='mt-4'>
            <div className='flex items-center'>
              <p className="text-sm font-semibold leading-6 text-gray-900 mr-1">Start time:</p>
              <p className="text-sm text-gray-700 mr-3">{formatDate(startTime)}</p>
            </div>
            <div className='flex items-center'>
              <p className="text-sm font-semibold leading-6 text-gray-900 mr-1">End time:</p>
              <p className="text-sm text-gray-700 mr-3">{formatDate(endTime)}</p>
            </div>
          </div>
          <div className='mt-2 flex items-center'>
            <DateRangePickerWidget updateDates={updateDates} />
            <button
              onClick={() => updateDatesAndFetch()}
              className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Update
            </button>
          </div>
        </div>
      </div>

      {selectedUser !== '' && (
        <div className='mt-4'>
          <div className="flex items-center">
            <p className="text-sm font-semibold leading-6 text-gray-900 mr-1">Filter by user:</p>
            {/* <p className="text-sm text-gray-700 mr-3">{selectedUser ? users[selectedUser] : 'All'}</p> */}
          </div>
          <span
            onClick={() => changeSelectedUser('')}
            className="cursor-pointer inline-flex items-center gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
            {selectedUser ? users[selectedUser] : 'All'}
            <button
              type="button"
              className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
            >
              <span className="sr-only">Remove</span>
              <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75">
                <path d="M4 4l6 6m0-6l-6 6" />
              </svg>
              <span className="absolute -inset-1" />
            </button>
          </span>
        </div>
      )}

      <div className="mt-4 overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border">
        <dt className="truncate text-sm font-medium text-gray-500">Billing</dt>
        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
          {totalRequests} requests
          <BillingChart billingChart={billingChart} />
        </dd>
      </div>

      {extractaAiBilling.length > 0 &&
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-2">Extracta.ai Marketplaces</h3>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        User
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Number of request
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        API
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Web
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Errors
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {extractaAiBilling.map((userBilling, index) => (
                      <tr
                        key={index}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 inline-flex items-center justify-center">
                          <p
                            onClick={() => changeSelectedUser(userBilling.userId)}
                            className="hover:text-indigo-600 cursor-pointer"
                          >
                            {users[userBilling.userId] ? users[userBilling.userId] : userBilling.userId}
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userBilling.billingItems.length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === "api" || billingItem.origin === "syncAPI" || billingItem.origin === "edenAi").length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === "web").length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === undefined).length}
                        </td>
                      </tr>
                    ))}
                    {/* <tr className="bg-gray-100">
                    <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Total
                    </td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {extractaAiTotalRequests}
                    </td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }

      {teamBilling.length > 0 && (
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-2">Extracta.ai Team</h3>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        User
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Number of request
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        API
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Web
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Errors
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {teamBilling.map((userBilling, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {/* {userBilling.userId} */}
                          {/* search in the users map with userId, if you find something the display that, otherwise display the userId */}
                          {users[userBilling.userId] ? users[userBilling.userId] : userBilling.userId}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userBilling.billingItems.length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === "api" || billingItem.origin === "syncAPI" || billingItem.origin === "edenAi").length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === "web").length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === undefined).length}
                        </td>
                      </tr>
                    ))}
                    {/* <tr className="bg-gray-100">
                    <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Total
                    </td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      {teamTotalRequests}
                    </td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                    <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {otherBilling.length > 0 &&
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <h3 className="text-lg font-semibold leading-6 text-gray-900 mb-2">Others</h3>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        User
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Number of request
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        API
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Web
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Errors
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {otherBilling.map((userBilling, index) => (
                      <tr
                        key={index}
                        onMouseEnter={() => setHoveredRowIndex(index)}
                        onMouseLeave={() => setHoveredRowIndex(null)}
                        className="hover:bg-gray-100"
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 inline-flex items-center justify-center">
                          <p
                            onClick={() => changeSelectedUser(userBilling.userId)}
                            className="hover:text-indigo-600 cursor-pointer"
                          >
                            {users[userBilling.userId] ? users[userBilling.userId] : userBilling.userId}
                          </p>
                          {hoveredRowIndex === index && (
                            <button
                              onClick={() => handleButtonClick(userBilling.userId)}
                              className="ml-2 px-2 bg-indigo-500 hover:bg-indigo-700 text-white font-bold rounded"
                            >
                              Show
                            </button>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {userBilling.billingItems.length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === "api" || billingItem.origin === "syncAPI" || billingItem.origin === "edenAi").length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === "web").length}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {/* calculate number of request based on origin */}
                          {userBilling.billingItems.filter((billingItem) => billingItem.origin === undefined).length}
                        </td>
                      </tr>
                    ))}
                    {/* <tr className="bg-gray-100">
                      <td className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Total
                      </td>
                      <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {otherTotalRequests}
                      </td>
                      <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                      <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                      <td className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      }

    </div>
  )
}

const customTooltipUsers = ({ payload, active, label }) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      <p className="font-medium text-tremor-content-emphasis mb-1">{label}</p>
      <div className="flex flex-row">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5 mt-1">
            <div className={`w-1 flex flex-col bg-${category.color}-500 rounded`} />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">{category.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};


const customTooltip = ({ payload, active, label }) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      <p className="font-medium text-tremor-content-emphasis mb-1">{label}</p>
      <div className="flex flex-row">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5 mt-1">
            <div className={`w-1 flex flex-col bg-${category.color}-500 rounded`} />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">{category.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const valueFormatter = function (number) {
  return number.toString();
};

const BillingChart = ({ billingChart }) => {
  return (
    <AreaChart
      className="h-60 mt-4"
      data={billingChart}
      index="day"
      categories={["Requests"]}
      colors={["indigo", "cyan", "red"]}
      showAnimation={true}
      showLegend={true}
      allowDecimals={false}
      valueFormatter={valueFormatter}
      customTooltip={customTooltip}
      yAxisWidth={68}
    />
  );
}

function CreditsDashboard({
  usersInfo,
}) {
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  function formatDate2(dateString) {
    const options = { month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [history, setHistory] = useState([]);

  const [creditsTotal, setCreditsTotal] = useState(0);
  const [creditsToday, setCreditsToday] = useState(0);
  const [creditsMonth, setCreditsMonth] = useState(0);
  const [credistLastMonth, setCreditsLastMonth] = useState(0);

  const [creditsChart, setCreditsChart] = useState([]);

  const [lastExtractionEnabled, setLastExtractionEnabled] = useState(false);
  const [lastExtractionLoading, setLastExtractionLoading] = useState(false);
  const [lastExtractions, setLastExtractions] = useState({});

  const switchLastExtraction = async () => {
    setLastExtractionLoading(true);
    setLastExtractionEnabled(true);

    let lastExtractions = {};
    try {
      let uniqueUserIds = [...new Set(history.map(item => item.userId))];
      for (let userId of uniqueUserIds) {
        console.log('Fetching last extraction for user', userId);
        const billingRef = ref(db, `billing/${userId}`);
        const snapshot = await get(billingRef);
        let data = snapshot.val();
        if (data) {
          let lastExtraction = null;

          if (data.success) {
            Object.keys(data.success).forEach((key) => {
              if (lastExtraction === null || data.success[key].timestamp > lastExtraction.timestamp) {
                lastExtraction = data.success[key];
              }
            });
          } else {
            Object.keys(data).forEach((key) => {
              if (lastExtraction === null || data[key].timestamp > lastExtraction.timestamp) {
                lastExtraction = data[key];
              }
            });
          }

          lastExtractions[userId] = lastExtraction;
        } else {
          console.log('No data found for user', userId);
        }
      };
    } catch (error) {
      console.error('Error fetching last extractions', error);
    }

    console.log('lastExtractions', lastExtractions);

    setLastExtractionLoading(false);
    setLastExtractions(lastExtractions);
  }

  const getLastExtraction = (userId) => {
    if (lastExtractionEnabled) {
      const lastExtraction = lastExtractions[userId];
      if (lastExtraction) {
        return formatDate(lastExtraction.timestamp);
      } else {
        return 'no-extractions';
      }
    }
    return 'not-enabled';
  }

  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesBefore, setTotalPagesBefore] = useState(0);
  const [totalPagesPartners, setTotalPagesPartners] = useState(0);
  const [totalPagesJustin, setTotalPagesJustin] = useState(0);

  const [totalPagesPrice, setTotalPagesPrice] = useState(0);
  const [totalPagesBeforePrice, setTotalPagesBeforePrice] = useState(0);
  const [totalPagesPartnersPrice, setTotalPagesPartnersPrice] = useState(0);
  const [totalPagesJustinPrice, setTotalPagesJustinPrice] = useState(0);

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        let localTotalPages = 0;
        let localTotalPagesBefore = 0;
        let localTotalPagesPartners = 0;
        let localTotalPagesJustin = 0;

        let localTotalPagesPrice = 0;
        let localTotalPagesBeforePrice = 0;
        let localTotalPagesPartnersPrice = 0;
        let localTotalPagesJustinPrice = 0;

        let localTotalUsers = 0;
        let localUsersIds = [];

        let localTotalTransactions = 0;

        // Fetch all user billing data within the time range
        const billingRef = ref(db, 'userDetails');
        get(billingRef).then((snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();

            let history = [];
            Object.keys(data).forEach((userId) => {
              console.log('userId', userId, data[userId]);
              if (data[userId].credits) {
                if (data[userId].credits.history) {
                  Object.keys(data[userId].credits.history).forEach((historyKey) => {
                    let element = data[userId].credits.history[historyKey];
                    let elementPages = 0;

                    if (element.pages) {
                      elementPages = parseFloat(element.pages);
                    } else {
                      if (element.stripeSession.amount_total === 0) {
                        console.log("wtf romania");
                        elementPages = parseFloat(element.credits) / 10;
                      } else {
                        console.log("wtf ucraina");
                        elementPages = parseFloat(element.stripeSession.amount_total) / 10;
                      }
                    }

                    history.push({
                      userId: userId,
                      email: data[userId].email,
                      credits: element.value,
                      amount_total: element.stripeSession?.amount_total ?? 0,
                      pages: elementPages,
                      type: element.type ?? 'pay-as-you-go',
                      timestamp: element.timestamp
                    });

                    if (userId === '4wYYo3brtUPzRhD0zkxEpO1zfPU2') {
                      let localPage = (element.pages ? parseFloat(element.pages) : 0);
                      localTotalPagesPartners += localPage;
                      try {
                        localTotalPagesPartnersPrice += (element.stripeSession?.amount_total ?? 0) / 100;
                      } catch (error) {
                      }
                    } else {
                      let localPage = (element.pages ? parseFloat(element.pages) : 0);

                      if (localPage === 0) {
                        let price = element.stripeSession?.amount_total ?? 0;
                        localTotalPagesBefore += (price / 100) * 10;
                        localTotalPagesBeforePrice += price / 100;
                      } else {
                        if (userId === 'cetWAxqthaMbpjvhEqUN6OY8WV03') {
                          localTotalPagesJustin += localPage;
                          try {
                            localTotalPagesJustinPrice += (element.stripeSession?.amount_total ?? 0) / 100;
                          } catch (error) {
                          }
                        } else {
                          localTotalPages += localPage;
                          try {
                            localTotalPagesPrice += (element.stripeSession?.amount_total ?? 0) / 100;
                          } catch (error) {
                          }
                        }
                      }
                    }

                    localUsersIds.push(userId);

                    localTotalTransactions += 1;
                  });
                }
              }
            });

            setTotalPages(localTotalPages);
            setTotalPagesBefore(localTotalPagesBefore);
            setTotalPagesPartners(localTotalPagesPartners);
            setTotalPagesJustin(localTotalPagesJustin);

            setTotalPagesPrice(localTotalPagesPrice);
            setTotalPagesBeforePrice(localTotalPagesBeforePrice);
            setTotalPagesPartnersPrice(localTotalPagesPartnersPrice);
            setTotalPagesJustinPrice(localTotalPagesJustinPrice);

            localUsersIds = [...new Set(localUsersIds)];
            localTotalUsers = localUsersIds.length;
            setTotalUsers(localTotalUsers);

            setTotalTransactions(localTotalTransactions);

            // sort history by timestamp
            history.sort((a, b) => {
              const dateA = new Date(a.timestamp);
              const dateB = new Date(b.timestamp);
              return dateB - dateA; // sort in descending order
            });

            // calculate total credits
            let today = new Date();
            let total = 0;
            let todayCredits = 0;
            let monthCredits = 0;
            let lastMonthCredits = 0;
            history.forEach((element) => {
              const date = new Date(element.timestamp);
              if (date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()) {
                todayCredits += element.amount_total;
              }
              if (date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()) {
                monthCredits += element.amount_total;
              }
              if (date.getMonth() === today.getMonth() - 1 &&
                date.getFullYear() === today.getFullYear()) {
                lastMonthCredits += element.amount_total;
              }
              total += element.amount_total;
            });

            // format credits to string, dollars, 2 decimals
            total = (total / 100).toFixed(2);
            todayCredits = (todayCredits / 100).toFixed(2);
            monthCredits = (monthCredits / 100).toFixed(2);
            lastMonthCredits = (lastMonthCredits / 100).toFixed(2);

            setCreditsTotal(total);
            setCreditsToday(todayCredits);
            setCreditsMonth(monthCredits);
            setCreditsLastMonth(lastMonthCredits);

            setHistory(history);
            setLoading(false);

            let creditsChart = [];

            history.forEach(item => {
              const timestamp = item.timestamp;
              const dateObj = new Date(timestamp);

              // Get the month and year
              const month = dateObj.getMonth(); // 0-11
              const year = dateObj.getFullYear();

              // Create a unique key for the month, e.g., '2023-10'
              const monthKey = `${year}-${(month + 1).toString().padStart(2, '0')}`;

              // Optionally, format the month to a readable string, e.g., 'October 2023'
              const monthName = dateObj.toLocaleString('default', { month: 'long' });
              const formattedMonth = `${monthName} ${year}`;

              // Check if a credit entry for this month already exists
              const existingEntry = creditsChart.find(entry => entry.monthKey === monthKey);

              if (existingEntry) {
                existingEntry.totalCredits += item.amount_total / 100;
                existingEntry.totalPages += item.pages ? parseFloat(item.pages) : 0;
              } else {
                // Create a new entry for this month
                const newEntry = {
                  date: new Date(year, month, 1), // First day of the month
                  monthKey: monthKey,             // e.g., '2023-10'
                  formattedMonth: formattedMonth, // e.g., 'October 2023'
                  totalCredits: item.amount_total / 100,
                  totalPages: item.pages ? parseFloat(item.pages) : 0,
                };

                creditsChart.push(newEntry);
              }
            });

            // i want the total credits to be a string and not a number, format with 2 decimals
            creditsChart.forEach(entry => {
              entry.totalCredits = entry.totalCredits.toFixed(2);
              // entry.totalPages = entry.totalPages.toFixed(2);
            });

            // sort the credits chart by date in ascending order
            creditsChart.sort((a, b) => a.date - b.date);

            // // credits chart for each month, add to the object the total pages for that month
            // creditsChart.forEach(entry => {
            //   let total = 0;
            //   history.forEach(item => {
            //     const timestamp = item.timestamp;
            //     const dateObj = new Date(timestamp);
            //     const month = dateObj.getMonth(); // 0-11
            //     const year = dateObj.getFullYear();
            //     const monthKey = `${year}-${(month + 1).toString().padStart(2, '0')}`;
            //     if (monthKey === entry.monthKey) {
            //       total += item.pages ? parseFloat(item.pages) : 0;
            //     }
            //   });
            //   entry.totalPages = total;
            // });

            // Fill in missing dates with 0 total credits
            // let filledCreditsChart = [];
            // let startDate = new Date(history[history.length - 1].timestamp);
            // let endDate = new Date();
            // let currentDate = new Date(startDate);

            // while (currentDate <= endDate) {
            //   const day = formatDate2(currentDate);

            //   const existingEntry = creditsChart.find(entry => entry.day === day);

            //   if (existingEntry) {
            //     filledCreditsChart.push(existingEntry);
            //   } else {
            //     filledCreditsChart.push({
            //       date: new Date(currentDate),
            //       day: day,
            //       totalCredits: "0.00"
            //     });
            //   }

            //   // Move to the next day
            //   currentDate.setDate(currentDate.getDate() + 1);
            // }

            // // Sort the filled data by date in ascending order
            // filledCreditsChart.sort((a, b) => a.date - b.date);

            setCreditsChart(creditsChart);
          }
        }).catch((error) => {
          setError(error);
          console.error('Error fetching user billing data', error);
        });

      } catch (error) {
        setError(error);
        console.error('Error fetching user billing data', error);
      }
    };

    fetchCredits();
  }, []);

  const [searchTerm, setSearchTerm] = useState('');

  const filteredHistory = history.filter((element) => {
    const email = usersInfo[element.userId]?.email || element.userId;
    return (
      email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      element.userId.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const [selectedUserId, setSelectedUserId] = useState('');
  const [open, setOpen] = useState(false);

  const handleButtonClick = (userId) => {
    console.log('Button clicked for user:', userId);
    setSelectedUserId(userId);
    setOpen(true);
  }

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="mt-4 flow-root">
      <ModalCustom open={open} setOpen={setOpen} >
        <ShowUserDetails userId={selectedUserId} closeModal={() => {
          setOpen(false);
          setSelectedUserId('');
        }} />
      </ModalCustom>

      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <DataDisplayCredits
            today={creditsToday}
            total={creditsTotal}
            month={creditsMonth}
            lastMonth={credistLastMonth}
            totalClients={totalUsers}
            totalTransactions={totalTransactions}
            totalPages={totalPages + totalPagesBefore + totalPagesPartners + totalPagesJustin}
            pricePerPage={((totalPagesPrice + totalPagesBeforePrice + totalPagesPartnersPrice + totalPagesJustinPrice) / (totalPages + totalPagesBefore + totalPagesPartners + totalPagesJustin)).toFixed(2)}
          />

          {/* <div>
            Total pages: {totalPages} Total price: {totalPagesPrice} Price per page: {(totalPagesPrice / totalPages).toFixed(2)}
            <br />
            Total pages justin: {totalPagesJustin} Total price justin: {totalPagesJustinPrice} Price per page justin: {(totalPagesJustinPrice / totalPagesJustin).toFixed(2)}
            <br />
            Total pages (+ justin): {totalPages + totalPagesJustin} Total price (+ justin): {totalPagesPrice + totalPagesJustinPrice}
            <br />
            Total pages before: {totalPagesBefore} Total price before: {totalPagesBeforePrice} Price per page before: {(totalPagesBeforePrice / totalPagesBefore).toFixed(2)}
            <br />
            Total pages partners: {totalPagesPartners} Total price partners: {totalPagesPartnersPrice} Price per page partners: {(totalPagesPartnersPrice / totalPagesPartners).toFixed(2)}
          </div> */}

          <div className="mt-5 overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border">
            <dt className="truncate text-sm font-medium text-gray-500">Credits</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              <CreditsChart chartData={creditsChart} />
            </dd>
          </div>

          <div className="mt-5 flex items-center px-4 py-3 bg-gray-50 sm:px-4">
            <input
              type="text"
              placeholder="Search by email or user ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 mr-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <button
              onClick={() => switchLastExtraction()}
              className="inline-flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 whitespace-nowrap"
            >
              Get last extractions
            </button>
          </div>

          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Value
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Pages
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  {lastExtractionEnabled && (
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Last Extraction
                    </th>
                  )}
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {filteredHistory.map((element, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <div className="flex items-center">
                        <span>{usersInfo[element.userId] ? usersInfo[element.userId].email : element.userId}</span>
                        <button
                          className="ml-2 text-indigo-600 hover:text-indigo-800"
                          onClick={() => {
                            handleButtonClick(element.userId);
                          }}
                          title="Show"
                        >
                          Show
                        </button>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.amount_total > 0 && element.credits > 0 && element.amount_total !== element.credits && (
                        <span className="mr-1 text-xs"><del>${(element.credits / 100).toFixed(2)}</del></span>
                      )}
                      ${(element.amount_total / 100).toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.pages ? element.pages : (element.type === 'pay-as-you-go' ? (((element.amount_total > 0 && element.credits > 0 && element.amount_total !== element.credit) ? element.credits : element.amount_total) / 100 * 10) : '-')}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.type ? element.type : '-'}
                    </td>
                    {lastExtractionEnabled && (
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {lastExtractionLoading ? 'Loading...' : getLastExtraction(element.userId)}
                      </td>
                    )}
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {formatDate(element.timestamp)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

const customTooltipCredits = ({ payload, active, label }) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      <p className="font-medium text-tremor-content-emphasis mb-1">{label}</p>
      <div className="flex flex-row">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5 mt-1">
            <div className={`w-1 flex flex-col bg-${category.color}-500 rounded`} />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">{category.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CreditsChart = ({ chartData }) => {
  let maxValue = 13000;

  return (
    <AreaChart
      className="h-60 mt-4"
      data={chartData}
      index="formattedMonth"
      categories={["totalCredits"]}
      colors={["indigo", "cyan", "red"]}
      showAnimation={true}
      showLegend={true}
      allowDecimals={false}
      valueFormatter={valueFormatter}
      customTooltip={customTooltipCredits}
      yAxisWidth={68}
      showYAxis={true}
      showXAxis={true}
      maxValue={maxValue}
      style={{ width: '100%', height: '350px' }}
    />
  );
}

function CreditsDashboardHistory({ usersInfo }) {
  // const formatDate as dd MMM yyyy, hh:mm
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [buyTrigger, setBuyTrigger] = useState([]);

  useEffect(() => {
    const fetchBuyTrigger = async () => {
      try {
        // Fetch all user billing data within the time range
        const buyTriggerRef = ref(db, 'stripe/logs');
        get(buyTriggerRef).then((snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();

            let history = [];
            Object.keys(data).forEach((transactionId) => {
              let element = data[transactionId];
              history.push({
                userId: element.userId,
                value: element.value,
                currency: element.currency,
                pages: element.pages,
                type: element.type,
                timestamp: element.timestamp,
                transactionId: transactionId,
              });
            });

            // sort history by timestamp
            history.sort((a, b) => {
              const dateA = new Date(a.timestamp);
              const dateB = new Date(b.timestamp);
              return dateB - dateA; // sort in descending order
            });

            setBuyTrigger(history);
          }
        }).catch((error) => {
          console.error('Error fetching user billing data', error);
        });

      } catch (error) {
        console.error('Error fetching user billing data', error);
      }
    };
    fetchBuyTrigger();
  }, []);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="mt-4 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className='mb-5'></div>
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    User Id
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Value
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Pages
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Type
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {buyTrigger.map((element, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {usersInfo[element.userId] ? usersInfo[element.userId].email : element.userId}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.userId}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      ${(element.value / 100).toFixed(2)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {/* if element.pages is present put x pages else put - */}
                      {element.pages ? element.pages : '-'}
                      {element.pages ? ' pages' : ''}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.type ? element.type : '-'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {formatDate(element.timestamp)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function DataDisplayCredits({ today, month, lastMonth, total, totalPages, totalClients, totalTransactions, pricePerPage }) {
  const stats = [
    { name: 'Credits Today', stat: today, icon: true },
    { name: 'Credits Current Month', stat: month, icon: true },
    { name: 'Credits Last Month', stat: lastMonth, icon: true },
    { name: 'Total Credits', stat: total, icon: true },
    { name: 'Total Pages', stat: totalPages, icon: false },
    { name: 'Price per page', stat: pricePerPage, icon: true },
    { name: 'Total Clients', stat: totalClients, icon: false },
    { name: 'Total Transactions', stat: totalTransactions, icon: false },
  ];

  // format each stat, transform to double, convert to string, add thousands separator
  stats.forEach((item) => {
    if (item.icon) {
      item.stat = parseFloat(item.stat).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      item.stat = parseFloat(item.stat).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  });

  return (
    <div>
      <dl className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-4">
        {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.icon ? '$' : ''}{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

function SubscriptionsDashboard({ usersInfo }) {
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  function formatDate2(dateString) {
    const options = { month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const formatDate3 = (timestamp) => {
    // Convert the timestamp to milliseconds
    const date = new Date(timestamp * 1000);

    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract the day, month, year, hours, and minutes
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    // Format the date and time
    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [history, setHistory] = useState([]);

  const [creditsTotal, setCreditsTotal] = useState(0);
  const [creditsToday, setCreditsToday] = useState(0);

  const [creditsChart, setCreditsChart] = useState([]);

  const [subscriptions, setSubscriptions] = useState([]);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        // Fetch all user billing data within the time range
        const billingRef = ref(db, 'userDetails');
        get(billingRef).then((snapshot) => {
          if (snapshot.exists()) {
            let data = snapshot.val();

            let history = [];
            let subscriptions = [];
            Object.keys(data).forEach((userId) => {
              console.log('userId', userId, data[userId]);
              if (data[userId].credits) {
                if (data[userId].credits.history) {
                  Object.keys(data[userId].credits.history).forEach((historyKey) => {
                    let element = data[userId].credits.history[historyKey];
                    history.push({
                      userId: userId,
                      email: data[userId].email,
                      credits: element.value,
                      amount_total: element.stripeSession?.amount_total ?? 0,
                      pages: element.pages,
                      type: element.type,
                      timestamp: element.timestamp
                    });
                  });
                }

                if (data[userId].credits.subscriptions) {
                  Object.keys(data[userId].credits.subscriptions).forEach((subscriptionKey) => {
                    let element = data[userId].credits.subscriptions[subscriptionKey];
                    subscriptions.push({
                      userId: userId,
                      email: data[userId].email,
                      subscription: element,
                    });
                  });
                }
              }
            });

            // sort the subscriptions by element.subscription.period.created
            subscriptions.sort((a, b) => {
              const dateA = new Date(a.subscription.period.created);
              const dateB = new Date(b.subscription.period.created);
              return dateB - dateA; // sort in descending order
            });

            setSubscriptions(subscriptions);

            // sort history by timestamp
            history.sort((a, b) => {
              const dateA = new Date(a.timestamp);
              const dateB = new Date(b.timestamp);
              return dateB - dateA; // sort in descending order
            });

            // calculate total credits
            let today = new Date();
            let total = 0;
            let todayCredits = 0;
            history.forEach((element) => {
              const date = new Date(element.timestamp);
              if (date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()) {
                todayCredits += element.amount_total;
              }
              total += element.amount_total;
            });

            // format credits to string, dollars, 2 decimals
            total = (total / 100).toFixed(2);
            todayCredits = (todayCredits / 100).toFixed(2);

            setCreditsTotal(total);
            setCreditsToday(todayCredits);

            setHistory(history);
            setLoading(false);

            let creditsChart = [];

            history.forEach(item => {
              const timestamp = item.timestamp;
              const day = formatDate2(new Date(timestamp));
              const newTimestamp = new Date(timestamp);

              // Check if a credit entry for this day already exists
              const existingEntry = creditsChart.find(entry => entry.day === day);

              if (existingEntry) {
                existingEntry.totalCredits += (item.amount_total / 100);
              } else {
                // Create a new entry for this day
                const newEntry = {
                  date: newTimestamp, // Store the actual Date object for sorting
                  day: day,           // The formatted day string
                  totalCredits: (item.amount_total / 100)
                };

                creditsChart.push(newEntry);
              }
            });

            creditsChart.sort((a, b) => a.date - b.date);
            creditsChart.forEach(entry => { entry.totalCredits = entry.totalCredits.toFixed(2) });

            setCreditsChart(creditsChart);
          }
        }).catch((error) => {
          setError(error);
          console.error('Error fetching user billing data', error);
        });

      } catch (error) {
        setError(error);
        console.error('Error fetching user billing data', error);
      }
    };

    fetchCredits();
  }, []);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="mt-4 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {/* <DataDisplayCredits today={creditsToday} total={creditsTotal} />

          <div className="mt-5 overflow-hidden rounded-lg bg-white px-4 py-5 sm:p-6 border">
            <dt className="truncate text-sm font-medium text-gray-500">Credits</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              ${creditsTotal}
              <CreditsChart chartData={creditsChart} />
            </dd>
          </div>*/}

          <div className='mb-5'></div>

          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Email
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    User Id
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Plan
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Period
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {subscriptions.map((element, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {usersInfo[element.userId] ? usersInfo[element.userId].email : element.userId}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.userId}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {/* here if plan then show the price then show the number of pages */}
                      {element.subscription.plan ? '$' + (element.subscription.plan.price / 100) + ' / ' + element.subscription.plan.pages + ' pages' : '-'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {/* if element.subscription.period is here, then show .startDate and .endDate. transform from date epoch to my format */}
                      {element.subscription.period ? formatDate3(element.subscription.period.startDate) + ' - ' + formatDate3(element.subscription.period.endDate) : '-'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.subscription.period ? formatDate3(element.subscription.period.created) : '-'}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {element.subscription.status ? element.subscription.status : '-'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}