
import { useState, useContext } from 'react';
import { AuthContext } from '../../utils/auth.js';
import { PhotoIcon } from '@heroicons/react/24/solid'
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import FileList from '../utils/FileList';
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';

function ThirdStep({ nextStep, previousStep, parentParams }) {
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const environment = process.env.REACT_APP_ENV;

    const { currentUser } = useContext(AuthContext);

    const initialFiles = parentParams?.files || [];
    const [selectedFiles, setSelectedFiles] = useState(initialFiles);

    const checkAcceptedFileFormats = (file) => {
        const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/tiff', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain', 'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
        return acceptedFileTypes.includes(file.type);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        let files = e.dataTransfer.files;

        // filter out files that are not accepted
        files = [...files].filter((file) => checkAcceptedFileFormats(file));

        setSelectedFiles([...selectedFiles, ...files]);
    };

    const handleFileInputChange = (e) => {
        let files = e.target.files;

        // filter out files that are not accepted
        files = [...files].filter((file) => checkAcceptedFileFormats(file));

        setSelectedFiles([...selectedFiles, ...files]);
    };

    const splitPdf = async (file, { from, to }) => {
        try {
            const arrayBuffer = await file.arrayBuffer();
            const pdfDoc = await PDFDocument.load(arrayBuffer);
            const totalPages = pdfDoc.getPageCount();

            if (to > totalPages) to = totalPages;
            if (from > totalPages) throw new Error('Invalid page range');

            const newPdf = await PDFDocument.create();
            const pages = await newPdf.copyPages(pdfDoc, Array.from({ length: to - from + 1 }, (_, index) => from - 1 + index));
            pages.forEach((page) => newPdf.addPage(page));

            const newPdfBytes = await newPdf.save();
            const blob = new Blob([newPdfBytes], { type: 'application/pdf' });
            const newFile = new File([blob], file.name, { type: 'application/pdf' });

            return newFile;
        } catch (error) {
            console.error('Failed to split PDF:', error);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const processedFiles = await Promise.all(Object.keys(selectedFiles).map(async (key) => {
            const file = selectedFiles[key];

            // Check if the file is a PDF and if specific page processing is enabled
            if (file.type === "application/pdf" && parentParams.options.specificPageProcessing) {
                try {
                    console.log(`Splitting PDF file ${file.name}...`);
                    return await splitPdf(file, parentParams.options.specificPageProcessingOptions);
                } catch (error) {
                    console.error(`Failed to split PDF file ${file.name}:`, error);
                    return null; // Skip this file if there's an error
                }
            } else {
                return file;
            }
        }));

        // Filter out any null values (in case any files failed to process)
        const validFiles = processedFiles.filter(file => file !== null);

        nextStep({
            files: validFiles
        });
    }

    const firstExtractionTask = async () => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/firstExtraction";

            await axios.post(url, {
                // nothing
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then((response) => {
                // nothing
            }).catch((error) => {
                // nothing
            });
        });
    }

    const createExtraction = async (params, callback) => {
        await currentUser.getIdToken().then(async (idToken) => {
            const url = serverUrl + "/createExtraction";

            await axios.post(url, {
                "extractionDetails": {
                    "name": params.name,
                    "description": params.description,
                    "language": params.lang,
                    "fields": params.fields,
                    "options": params.options,
                    "workflowId": params.workflowId,
                    "createdAt": Date.now(),
                }
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${idToken}`
                }
            }).then(async (response) => {
                if (params.extractionsLength === 0) {
                    window.dataLayer.push({
                        event: "extraction_created",
                        extractionID: response.data.extractionID,
                        extractionName: params.name,
                        extractionLanguage: params.lang,
                    });
                    await firstExtractionTask();
                }
                callback(response.data.extractionId);
            }).catch((error) => {
                console.log(error);
            });
        });
    }

    const skipButton = async () => {
        createExtraction(parentParams, (extractionId) => {
            nextStep({ closeModal: true, extractionId: extractionId });
        });
    }

    return (
        <div className="bg-white sm:rounded-lg">
            <div className="">
                {previousStep !== undefined &&
                    <button
                        onClick={previousStep}
                        className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <ArrowLeftIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Back
                    </button>
                }
                <h3 className="text-base font-semibold leading-6 text-gray-900">Upload your files</h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Create a new batch uploading some files!</p>
                </div>
                <div className="w-full flex flex-col md:flex-row mt-8">
                    <div
                        className='w-full flex flex-col justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'
                        style={{ backgroundColor: '#F1F3F5' }}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                    >
                        <div className="text-center">
                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
                            <div className="mt-4 flex items-center text-sm leading-6 text-gray-600">
                                <label
                                    htmlFor="file-upload"
                                    className="px-2 py-0.5 relative cursor-pointer rounded-md font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                    <span>Upload a file</span>
                                    <input id="file-upload" name="file-upload" type="file" multiple accept=".png, .jpg, .jpeg, .gif, .bmp, .pdf, .doc, .docx, .txt" className="sr-only" onChange={handleFileInputChange} />
                                </label>
                                <p className="">or drag and drop</p>
                            </div>
                            <p className="mt-1 text-xs leading-5 text-gray-600">Photo, PDF, Word or .txt</p>
                        </div>
                    </div>
                </div>
                {selectedFiles.length > 0 && (
                    <>
                        <div className="bg-white rounded-lg px-4 mt-5 ring-1 ring-slate-900/5 shadow-xl">
                            <FileList
                                files={Object.keys(selectedFiles).map((key) => { return selectedFiles[key] })}
                                isUpload={false}
                                removeFile={(index) => {
                                    let files = Object.keys(selectedFiles).map((key) => { return selectedFiles[key] });
                                    files.splice(index, 1);
                                    setSelectedFiles(files);
                                }}
                            />
                        </div>
                    </>
                )}
                <div></div>
                <div className="sm:col-span-4 mt-8">
                    <div className="col-span-full flex place-content-end mt-5">
                        {selectedFiles.length > 0 &&
                            <button
                                onClick={handleSubmit}
                                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Next
                            </button>}
                    </div>
                </div>
                {previousStep !== undefined && selectedFiles.length <= 0 && (
                    <div className="flex flex-col items-center">
                        <div className="flex items-center w-full mb-6">
                            <hr className="flex-grow border-gray-300" />
                            <span className="mx-4 text-gray-500">or</span>
                            <hr className="flex-grow border-gray-300" />
                        </div>
                        <button
                            onClick={skipButton}
                            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Skip
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ThirdStep